import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, Form, Button, Row, Col} from 'react-bootstrap';
import {FaUserEdit, FaCity, FaPen, FaRegEnvelope, FaPhone, FaProjectDiagram} from "react-icons/fa"
import {useTranslation} from 'gatsby-plugin-react-i18next'


const MyContactModal = ({show, setShow}) => {  

    const handleSubmit = (e) => {
        e.preventDefault()
        var formData = []
        const arr = [...e.currentTarget.elements]
        arr.forEach( (elt) => {
            const attrName = elt.name
            const attrValue = elt.value
            formData.push([attrName, attrValue])
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body:JSON.stringify(Object.fromEntries(formData))
            };
        fetch('https://dev.sim4sys.com/contact', requestOptions)
        .then(response => {
            console.log(response.json())
        })       
      };


    const {t} = useTranslation()
    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Contact</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group>
                        <Row>
                            <Col xs={{span: 1}}>
                                <FaUserEdit style={{verticalAlign: 'baseline', textAlign:'center'}}/>
                            </Col>
                            <Col xs={{span: 11}}>
                                <Form.Control name="name" type="text" placeholder={t("First_Name LAST_NAME")}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span: 1}}>
                                <FaCity style={{verticalAlign: 'bottom', textAlign:'center'}}/>
                            </Col>
                            <Col xs={{span: 11}}>
                                <Form.Control name="company" type="text" placeholder={t("Company")}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span: 1}}>
                                <FaProjectDiagram style={{verticalAlign: 'baseline', textAlign:'center'}}/>
                            </Col>
                            <Col xs={{span: 11}}>
                                <Form.Control name="position" type="text" placeholder={t("Position")}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span: 1}}>
                                <FaRegEnvelope style={{verticalAlign: 'baseline', textAlign:'center'}}/>
                            </Col>
                            <Col xs={{span: 11}}>
                                <Form.Control name="email" type="email" placeholder={t("Email")}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span: 1}}>
                                <FaPhone style={{verticalAlign: 'baseline', textAlign:'center'}}/>
                            </Col>
                            <Col xs={{span: 11}}>
                                <Form.Control name="phone" type="text" placeholder={t("Phone")}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span: 1}}>
                                <FaPen style={{verticalAlign: 'baseline', textAlign:'center'}}/>
                            </Col>
                            <Col xs={{span: 11}}>
                                <Form.Control name="message" as="textarea" rows="3" placeholder={t("Message")}/>
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>Cancel</Button>
                    <Button variant="primary" type="submit" onClick={() => setShow(false)}>Send</Button>
                </Modal.Footer>
            </Form>
        </Modal>         
    )
}

export default MyContactModal;