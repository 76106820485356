import React from "react"
import {Container, Row, Col} from 'react-bootstrap'
import "../assets/css/footer.css"
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {Link} from 'gatsby-plugin-react-i18next'


const MyFooter = () => { 
    const {t} = useTranslation()
    return (
        <Container fluid className="myfooter">
            <Row>
                <Col xs={{span: 12}} md={{span: 2}} style={{textAlign: "center"}}>
                    <Link to="/legal">{t("Legal notice")}</Link>
                </Col>
                <Col xs={{span: 12}} md={{span: 8}} style={{textAlign: "center"}}>
                    <span >CIL4Sys Engineering<sup>&reg;</sup></span>
                </Col>
            </Row>
        </Container>
    )
}

export default MyFooter

