import React from "react"
import {useStaticQuery,graphql} from "gatsby"

let SocialMenu = (props) => {
    const data = useStaticQuery(graphql`
        query MyNetworkQuery {
            allFile(filter: {relativeDirectory: {eq: "networksIcons"}}) {
                edges {
                    node {
                    publicURL,
                    name
                    }  
                }
            }
        }`
    )

    const currentIco = data.allFile.edges.filter((e)=>(e.node.name===props.network))[0].node.publicURL

    return (
        <a className="our-networks" href={props.link} target="_blank" rel="noreferrer">
            <img alt={props.network} src={currentIco} height="20px"/>            
        </a>
    )
}

export default SocialMenu;