import React from "react"
import { Helmet } from "react-helmet-async"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {useTranslation} from 'gatsby-plugin-react-i18next';

// const SEO = () => {
//     return (
//         <Helmet>
//             {/* <script crossorigin="anonymous" type="text/javascript" id="hs-script-loader" src="//js.hs-scripts.com/4619832.js"></script> */}
//         </Helmet>
//     )
// };

function SEO({ description, lang, meta, title}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            url
          }
        }
      }
    `
  )
  const keywords = site.siteMetadata.keywords
  const metaDescription = description || site.siteMetadata.description
  const {t} = useTranslation()  
  const defaultTitle = t("Site title") || site.siteMetadata.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `title`,
          content: defaultTitle,
        }
      ].concat(meta)}
    >
      <title>{defaultTitle}</title>

    </Helmet>
  )}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}
  
SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}
  
export default SEO;



