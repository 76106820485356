import React from "react";
import {Navbar, Container, Nav, Dropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/images/logo-complet-white-text.svg';
import LangMenu from '../components/langMenu.js';
import {useI18next, Link} from 'gatsby-plugin-react-i18next'
import {useStaticQuery,graphql} from "gatsby"
import "../assets/css/nav.css"
import {FaRegEnvelope} from "react-icons/fa"
import SocialMenu from "./socialMenu";


const MyNav = ({show, setShow}) => {
    const {language} = useI18next();
    const query1 = useStaticQuery(graphql`
        query MyQuery1 {
            allStrapiNavdropdown {
                nodes {
                  text
                  lang {
                    tag
                  }
                  rank
                  link
                  navlinks {
                    text
                    link
                    rank
                  }
                }
            }
        }`
    );
   
    const navdropdowns = query1.allStrapiNavdropdown.nodes.filter((e)=>(e.lang.tag===language)).sort((a,b)=>( a.rank > b.rank ? 1 : -1 ))

    return (
        <Navbar bg="dark" variant="dark" expand="sm" sticky="top">
            <Container>
                <Navbar.Brand href="/" className="logo">
                <img
                    alt=""
                    src={logo}
                    height="40"
                    // className="d-inline-block align-top"
                />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-around" justify="true">
                    <Nav>
                        {navdropdowns.map((node)=> {
                            const page=node.link
                            const thisNavLinks = node.navlinks.sort((a,b)=>( a.rank > b.rank ? 1 : -1 ))
                            return (
                                <Dropdown key={page} as={Nav.Item}>
                                <Dropdown.Toggle as={Nav.Link}>
                                    <span className="caret"/>
                                    <span className="myMenu">{node.text}</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {thisNavLinks.map((navlink)=>
                                        <Dropdown.Item as={Link} key={navlink.link} language={language} to={"/"+page+navlink.link}>
                                            <span className="myMenu"> {navlink.text} </span>
                                        </Dropdown.Item> 
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            )}
                        )}
                    </Nav>
                    <Nav>
                        <LangMenu/>                   
                    </Nav>
                    <Nav>
                        <Link to='#' className="our-networks">
                            <FaRegEnvelope style={{fontSize:'1.4em', color:'white'}} onClick={() => setShow(true)}/>
                        </Link>
                        <SocialMenu network='linkedin' link='https://www.linkedin.com/company/11082190/'/>
                        <SocialMenu network='twitter' link='https://twitter.com/CIL4Sys'/>
                        <SocialMenu network='youTube' link='https://www.youtube.com/channel/UCvOooMH65csdapejZkFYstg'/>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default MyNav;
