import React, {useState} from "react"
import {Dropdown, Nav} from 'react-bootstrap'
import {Link, useI18next} from 'gatsby-plugin-react-i18next'
import {useStaticQuery,graphql} from "gatsby"
import window from "global"

let LangMenu = () => {
    const {languages, language, originalPath} = useI18next();
    const data = useStaticQuery(graphql`
        query MyLangQuery {
            allFile(filter: {relativeDirectory: {eq: "langFlags"}}) {
                edges {
                    node {
                    publicURL,
                    name
                    }  
                }
            }
        }`
    )
    const langArray = data.allFile.edges
    const [pos,setPos]=useState(langArray.findIndex((e)=>(e.node.name===language)))
    return (
        <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link}>
                <span className="caret"/>
                <img height='18px' src={langArray[pos].node.publicURL} alt=""/>
                <span className="text-uppercase"> {langArray[pos].node.name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {languages.map((lng,i) => {
                    return (
                        <Dropdown.Item as={Link} key={lng} to={originalPath+window.location?.hash} language={lng} onClick={()=>setPos(i)}> 
                            <img height='18px' src={data.allFile.edges.filter((e)=>(e.node.name===lng))[0].node.publicURL} alt=""/>
                            <span className="text-uppercase"> {lng}</span>
                        </Dropdown.Item>
                )})}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default LangMenu;