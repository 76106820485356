/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState} from "react"
import PropTypes from "prop-types"

import Nav from "./nav"
import MyContactModal from "./contactModal"
import Seo from "./seo"
import MyFooter from "./myFooter"


const Layout = (props) => {
  const [show, setShow] = useState(false)
  let children = props.children
  children = React.Children.map(children, child =>
    React.cloneElement(child, { show: show, setShow: setShow }))
  return (
    <>
      <Seo />
      <Nav show={show} setShow={setShow}/>
      <MyContactModal show={show} setShow={setShow}/>
      <main>{children}</main>
      <MyFooter/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

